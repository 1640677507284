import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"

const LOCALES_REGEXP = new RegExp(`/(${AVAILABLE_LOCALES.join("|")})?$`)

export const isLinkActive = (currentPathname = "", pathToCheck = "", isStrict = false): boolean => {
  if (!pathToCheck || !currentPathname) {
    return false
  }

  if (currentPathname === pathToCheck) {
    return true
  }

  if (LOCALES_REGEXP.test(currentPathname)) {
    return false
  }

  const lastCharFromPath = pathToCheck.charAt(pathToCheck.length - 1)
  const formattedPath = (lastCharFromPath === "s" ? pathToCheck.slice(0, -1) : pathToCheck).split("?")[0]
  const formattedCurrentHref = currentPathname.split("?")[0]

  if (isStrict) {
    return currentPathname.endsWith(pathToCheck)
  }

  if (
    formattedCurrentHref.startsWith(formattedPath) ||
    currentPathname.startsWith(formattedPath) ||
    formattedPath.startsWith(formattedCurrentHref) ||
    formattedPath.startsWith(currentPathname)
  ) {
    return true
  }

  return false
}
