import { AVAILABLE_LOCALES } from "@/modules/i18n/constants"

export const isChildPage = (pathname: string): boolean => {
  return pathname.split("/").length > 3
}

export const isCorporatePage = (pathname: string): boolean => {
  const [_empty, _locale, featureName] = pathname.split("/")

  return featureName === "corporate"
}

export function isHome(pathname: string): boolean {
  return AVAILABLE_LOCALES.map(locale => new RegExp(`/${locale}/?$`)).some(regexp => regexp.test(pathname))
}
