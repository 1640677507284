"use client"

import type { AnchorHTMLAttributes, FunctionComponent, MouseEventHandler, PropsWithChildren } from "react"

import classNames from "classnames"
import { usePathname } from "next/navigation"

import { isLinkActive } from "@/modules/headerFooterFO/Header/helpers/isLinkActive"
import { Link } from "@/modules/routing/components/Link"

import dropdownMenuStyles from "@/modules/headerFooterFO/Header/styles/DropdownMenu.module.css"

type DropdownUserLinkProps = PropsWithChildren<
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
    href: string
    strictActiveCheck?: boolean
    useNextLink?: boolean
  }
>

export const DropdownUserLink: FunctionComponent<DropdownUserLinkProps> = ({
  children,
  href,
  strictActiveCheck,
  useNextLink = true,
  ...props
}) => {
  const pathname = usePathname()
  const Component = useNextLink ? Link : "a"

  const handleClick: MouseEventHandler<HTMLAnchorElement> = () => {
    // On iOS, after a click, the active element is the menu, not the link.
    if (document.activeElement) {
      ;(document.activeElement as HTMLElement).blur()
    }
  }

  return (
    <Component
      {...props}
      className={classNames(
        dropdownMenuStyles["hdr-DropdownMenu-submenuLink"],
        dropdownMenuStyles["hdr-DropdownMenu-submenuLink--withIcon"],
        {
          [dropdownMenuStyles["hdr-DropdownMenu-submenuLink--active"]]: isLinkActive(pathname, href, strictActiveCheck),
        }
      )}
      href={href}
      onClick={handleClick}
    >
      {children}
    </Component>
  )
}
