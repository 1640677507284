"use client"

import type { FunctionComponent, HTMLAttributes, PropsWithChildren } from "react"

import { usePathname } from "next/navigation"

import { isChildPage } from "@/modules/headerFooterFO/utils/isPage"

type InteractiveAppLocalesDropdownProps = PropsWithChildren<{
  isLogged: boolean
}> &
  HTMLAttributes<HTMLDivElement>

export const InteractiveAppLocalesDropdown: FunctionComponent<InteractiveAppLocalesDropdownProps> = ({
  children,
  className,
  isLogged,
}) => {
  const pathname = usePathname()

  if (isLogged || isChildPage(pathname)) {
    return null
  }

  return <div className={className}>{children}</div>
}
